<template>
  <div class="m-2" v-if="users.length > 0">
    <table-lite
      :has-checkbox="true"
      :is-static-mode="false"
      :columns="columns"
      :rows="rows"
      :total="totalRecordCount"
      :sortable="sortable"
    ></table-lite>
  </div>
</template>

<script>
import TableLite from "vue3-table-lite";
import { getUsersList } from "@/services/user/user.js";

export default {
  components: { TableLite },
  data() {
    return {
      users: [],
      columns: [],
      rows: null,
      totalRecordCount: null,
      sortable: {
        order: "id",
        sort: "asc",
      },
    };
  },
  created() {
    getUsersList().then((response) => {
      if (response.success) {
        this.users = response.data;
        this.columns = [
          {
            label: "ID",
            field: "id",
            width: "10%",
            sortable: true,
            isKey: true,
          },
          {
            label: "Name",
            field: "name",
            width: "30%",
            sortable: true,
            display: function (row) {
              return `<a href="/user/${row.id}">` + row.name + "</a>";
            },
          },
        ];
        this.rows = this.users;
        this.totalRecordCount = this.users.length;
      }
    });
  },
};
</script>
